import bookingActions from 'features/Booking/services/actions';
import { STEPS } from 'features/Booking/services/constants';
import { IServiceItem } from 'features/Booking/types/service';
import React from 'react';
import { useAppDispatch } from 'store/hooks';
import { styled } from 'styled-components';
import ServicesSelection from './ServicesSelection';
type IServiceTabContentProps = {};
const ServiceTabContent: React.FC<IServiceTabContentProps> = () => {
  const dispatch = useAppDispatch();
  const setCurrService = (val: IServiceItem | null | undefined) => {
    dispatch(bookingActions.setCurrService(val));
    setTimeout(() => {
      dispatch(bookingActions.setStep(STEPS.STEP_2_CHOOSE_SERVICE));
    }, 100);
  };

  return (
    <ServiceTabContentStyled>
      <ServicesSelection setCurrService={setCurrService} />
    </ServiceTabContentStyled>
  );
};

export default ServiceTabContent;
const ServiceTabContentStyled = styled.div``;
