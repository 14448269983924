import bookingActions from 'features/Booking/services/actions';
import { STEPS } from 'features/Booking/services/constants';
import { IStaffItem } from 'features/Booking/types/staff';
import React from 'react';
import { useAppDispatch } from 'store/hooks';
import { styled } from 'styled-components';
import StaffsSelection from './StaffsSelection';
type IStaffTabContentProps = {};
const StaffTabContent: React.FC<IStaffTabContentProps> = () => {
  const dispatch = useAppDispatch();
  const setStaff = (val: IStaffItem | null | undefined) => {
    dispatch(bookingActions.setCurrStaff(val));
    setTimeout(() => {
      dispatch(bookingActions.setStep(STEPS.STEP_2_CHOOSE_SERVICE));
    }, 100);
  };

  return (
    <StaffTabContentStyled>
      <StaffsSelection setStaff={setStaff} />
    </StaffTabContentStyled>
  );
};

export default StaffTabContent;
const StaffTabContentStyled = styled.div``;
