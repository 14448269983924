import { Button, Col, Row } from 'antd';
import AppBar from 'components/Atoms/AppBar';
import Container from 'components/layout/Container';
import bookingActions from 'features/Booking/services/actions';
import { STEPS, TAB } from 'features/Booking/services/constants';
import bookingSelector from 'features/Booking/services/selectors';
import { IServiceSelectedItem } from 'features/Booking/types/service';
import { remove } from 'lodash';
import React from 'react';
import { useAppDispatch } from 'store/hooks';
import { styled } from 'styled-components';
import ServiceSelectedItem from './ServiceSelectedItem';
type IAppointmentLayoutProps = {};
const AppointmentLayout: React.FC<IAppointmentLayoutProps> = () => {
  const dispatch = useAppDispatch();
  const activeTab = bookingSelector.getActiveTab();
  const appointments = bookingSelector.getAppointments();

  const onBack = () => {
    dispatch(bookingActions.setCurrService(null));
    dispatch(bookingActions.setCurrStaff(null));
    if (activeTab === TAB.STAFF) {
      dispatch(bookingActions.setStep(STEPS.STEP_1_SELLING));
      return;
    }
    dispatch(bookingActions.setStep(STEPS.STEP_2_CHOOSE_SERVICE));
  };
  const onAddNew = () => {
    dispatch(bookingActions.setCurrService(null));
    dispatch(bookingActions.setCurrStaff(null));
    dispatch(bookingActions.setStep(STEPS.STEP_2_CHOOSE_SERVICE));
  };

  const handleRemove = (curr: IServiceSelectedItem) => {
    const _appointments = [...appointments];
    remove(_appointments, o => o?.serviceId === curr?.serviceId && o?.staff_info.id === curr.staff_info?.id);
    dispatch(bookingActions.setAppointments(_appointments));
  };

  const onConfirm = async () => {
    dispatch(bookingActions.setStep(STEPS.STEP_4_CUSTOMER_INFO));
  };

  return (
    <AppointmentLayoutStyled>
      <AppBar title='Your appointment' className='mb-spacing' onBack={onBack} />
      <Container className='appointments'>
        <p className='label text-center'>Number guest: 1</p>
        {appointments.map(o => (
          <ServiceSelectedItem key={o.serviceId} data={o} onRemove={() => handleRemove(o)} />
        ))}
        <Button className='round' onClick={onAddNew}>Add more service</Button>
      </Container>
      <div className='position-bottom'>
        <Container>
          <Container offset={{ xs: 23, md: 19, lg: 17, xl: 15, xxl: 13 }}>
            <Row gutter={[16, 16]}>
              <Col flex={'auto'}>
                <Button className='round' onClick={onBack}>Back</Button>
              </Col>
              <Col flex={'auto'}>
                <Button type='primary' className='round' onClick={onConfirm}>Confirm</Button>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    </AppointmentLayoutStyled>
  );
};

export default AppointmentLayout;
const AppointmentLayoutStyled = styled.div`
.appointments {
  margin-bottom:10vh;
}
.text-center {
  text-align: center;
}
`;
