import { get } from 'lodash';
import uiSelector from 'services/UI/selectors';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { PATH_LOADING } from './constants';


type MyState = RootState['booking'];

const getCurrentState = (state: RootState): MyState => state.booking;
const selector = (key: keyof MyState, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getCurrentStep = () => selector('step') as MyState['step'];
const getCurrentService = () => selector('currService') as MyState['currService'];
const getCurrentStaff = () => selector('currStaff') as MyState['currStaff'];
const getActiveTab = () => selector('activeTab') as MyState['activeTab'];
const getAppointments = () => selector('appointments') as MyState['appointments'];
const getStoreInfo = () => selector('storeInfo') as MyState['storeInfo'];
const getStaffs = () => selector('staffs') as MyState['staffs'];
const getServices = () => selector('services') as MyState['services'];

const getLoading = () => uiSelector.getLoading(PATH_LOADING.getConfigData) as boolean;
const getAllStaffByShopLoading = () => uiSelector.getLoading(PATH_LOADING.getAllStaffByShop) as boolean;
const getServicesLoading = () => uiSelector.getLoading(PATH_LOADING.getServices) as boolean;

const getBookingDate = () => selector('bookingDate') as MyState['bookingDate'];

const bookingSelector = {
  getCurrentStep,
  getCurrentService,
  getCurrentStaff,
  getActiveTab,
  getAppointments,
  getStoreInfo,
  getStaffs,
  getServices,
  getLoading,
  getBookingDate,
  getAllStaffByShopLoading,
  getServicesLoading,
};

export default bookingSelector;
