import { createAction, createAsyncAction } from 'services/actionConfigs';
import { IState } from '../types/reducer';
import { PREFIX_ACTIONS } from './constants';
import { IStoreInfoResData } from '../types/store';

const setStep = createAction<IState['step']>(PREFIX_ACTIONS + 'SET_STEP');
const setCurrService = createAction<IState['currService']>(PREFIX_ACTIONS + 'setCurrService');
const setCurrStaff = createAction<IState['currStaff']>(PREFIX_ACTIONS + 'setCurrStaff');
const setActiveTab = createAction<IState['activeTab']>(PREFIX_ACTIONS + 'setActiveTab');
const setAppointments = createAction<IState['appointments']>(PREFIX_ACTIONS + 'setAppointments');

const getStoreInfoByUrlName = createAsyncAction<string>(PREFIX_ACTIONS + 'getStoreInfoByUrlName');
const getAllStaffByShop = createAsyncAction<IStoreInfoResData['id']>(PREFIX_ACTIONS + 'getAllStaffByShop');
const getServices = createAsyncAction<IStoreInfoResData['id']>(PREFIX_ACTIONS + 'getServices');

const setBookingDate = createAction<string>(PREFIX_ACTIONS + 'setBookingDate');

const resetData = createAction(PREFIX_ACTIONS + 'resetData');

const bookingActions = {
  setStep,
  setCurrService,
  setCurrStaff,
  setActiveTab,
  setAppointments,
  getStoreInfoByUrlName,
  getAllStaffByShop,
  getServices,
  setBookingDate,
  resetData,
};
export default bookingActions;
