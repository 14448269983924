import { Grid } from 'antd';
import { useMemo } from 'react';

const { useBreakpoint } = Grid;
const useDetachScreen = () => {
  const screens = useBreakpoint();
  const isBrowser = useMemo(() => {
    const { md, lg, xl, xxl } = screens;
    if (md || lg || xl || xxl) return true;
    return false;
  }, [screens]);

  return {
    isBrowser,
    isMobile: !isBrowser,
  };
};

export default useDetachScreen;
