import { Button, Card, Col, Row } from 'antd';
import avatar from 'assets/avt.png';
import { IStaffItem } from 'features/Booking/types/staff';
import React from 'react';
import { styled } from 'styled-components';
type IStaffItemProps = {
  data: IStaffItem;
  onClick?: () => void;
};
const StaffItem: React.FC<IStaffItemProps> = ({ data, onClick = () => undefined }) => {
  return (
    <StaffItemStyled onClick={onClick}>
      <Row gutter={[16, 0]} className='row-center staff-row'>
        <Col flex="auto">
          <Row className='row-center content-item'>
            <img src={avatar} className='avatar' />
            <p className='staff-name'>{data.fullName}</p>
          </Row>
        </Col>
        <Col className='ant-col-row-center'>
          <Button type="primary">Book</Button>
        </Col>
      </Row>
    </StaffItemStyled>
  );
};

export default StaffItem;
const StaffItemStyled = styled(Card)`
background-color: #fff;
border-color: #fff;
color: rgba(0,0,0,.87);
border: 0.5px solid #f1f0f5!important;
border-radius: 0.75rem;
box-shadow: 10px 24px 54px rgba(0,0,0,.06)!important;
margin: 0.5rem;
padding: 1.33rem 0.72rem;

.staff-row {
  flex-wrap: nowrap;
}
.content-item {
  gap:16px;
  flex-wrap: nowrap;
}
.avatar {
  border-radius: 0.625rem;
  height: 2.32rem;
  min-width: 2.32rem;
  overflow: hidden;
  width: 2.32rem;
}

.staff-name {
  color: #2f2c35;
  font-size: 18px;
  margin: 0 0.625rem;
  word-break: break-word;
}
`;
