import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import bookingActions from 'features/Booking/services/actions';
import bookingSelector from 'features/Booking/services/selectors';
import React from 'react';
import { useAppDispatch } from 'store/hooks';
import { styled } from 'styled-components';
type IBookingDateProps = {};
const BookingDate: React.FC<IBookingDateProps> = () => {
  const dispatch = useAppDispatch();
  const bookingDate = bookingSelector.getBookingDate();
  return (
    <BookingDateStyled>
      <DatePicker size='large' inputReadOnly
        value={dayjs(bookingDate)}
        onChange={day => {
          if (!day) return;
          dispatch(bookingActions.setBookingDate(day.format()));
        }} />
    </BookingDateStyled>
  );
};

export default BookingDate;
const BookingDateStyled = styled.div``;
