export const NAME_SERVICE = 'booking';

export const PREFIX_ACTIONS = 'BOOKING_';

export const PATH_LOADING = {
    getConfigData: `loading.${NAME_SERVICE}.getConfigData`,
    getAllStaffByShop: `loading.${NAME_SERVICE}.getAllStaffByShop`,
    getServices: `loading.${NAME_SERVICE}.getServices`,
};

export const STEPS = {
    STEP_1_SELLING: 'STEP_1_SELLING',
    STEP_2_CHOOSE_SERVICE: 'STEP_2_CHOOSE_SERVICE',
    STEP_3_APPOINTMENT: 'STEP_3_APPOINTMENT',
    STEP_4_CUSTOMER_INFO: 'STEP_4_CUSTOMER_INFO',
};

export const TAB = {
    SERVICE: 'service',
    STAFF: 'staff',
};