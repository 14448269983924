import fetch from 'services/request';
import { IReqBodyBookingAppointment, IStoreInfoResData } from '../types/store';

const getInfoByUrl = (urlName: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/booking/getInfoByUrl',
    params: {
      urlName
    },
    configs: {
      data: {}
    }
  });
};

const getAllStaffByShop = (shopId: IStoreInfoResData['id']) => {
  return fetch({
    method: 'get',
    url: 'api/v1/booking/getAllStaffByShop',
    params: { shopId }
  });
};

const getServices = (shopId: IStoreInfoResData['id']) => {
  return fetch({
    method: 'get',
    url: 'api/v1/booking/getServices',
    params: { shopId }
  });
};

const getTimeSlotsByStaff = ({ shopId, staffId, date }: { shopId: IStoreInfoResData['id'], staffId: string, date: string }) => {
  return fetch({
    method: 'get',
    url: 'api/v1/booking/getTimeSlotsByStaff',
    params: { shopId, staffId, date }
  });
};

const bookAppointmentV3 = (body: IReqBodyBookingAppointment) => {
  return fetch({
    method: 'post',
    url: 'api/v1/booking/bookAppointmentV3',
    body
  });
};

const bookingApis = {
  getInfoByUrl,
  getAllStaffByShop,
  getServices,
  getTimeSlotsByStaff,
  bookAppointmentV3,
};

export default bookingApis;
