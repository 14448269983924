import { Spin } from 'antd';
import Container from 'components/layout/Container';
import bookingActions from 'features/Booking/services/actions';
import { STEPS } from 'features/Booking/services/constants';
import bookingSelector from 'features/Booking/services/selectors';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { styled } from 'styled-components';
import HeadInfo from './components/HeadInfo';
import AppointmentLayout from './components/layouts/Appointment';
import SellingLayout from './components/layouts/Selling';
import ServiceLayout from './components/layouts/Service';
import CustomerInfo from './components/layouts/CustomerInfo';


const BookingPage: React.FC = () => {
  const step = bookingSelector.getCurrentStep();
  const dispatch = useAppDispatch();
  const { url_name = '', } = useParams();
  const loading = bookingSelector.getLoading();

  useEffect(() => {
    if (url_name) {
      dispatch(bookingActions.getStoreInfoByUrlName.fetch(url_name));
    }
  }, []);

  if (loading)
    return (
      <BookingPageStyled>
        <div className='loading'>
          <Spin spinning />
        </div>
      </BookingPageStyled>
    );

  return (
    <BookingPageStyled>
      <HeadInfo />
      <Container containerClassName='body'>
        {step === STEPS.STEP_1_SELLING && <SellingLayout />}
        {step === STEPS.STEP_2_CHOOSE_SERVICE && <ServiceLayout />}
        {step === STEPS.STEP_3_APPOINTMENT && <AppointmentLayout />}
        {step === STEPS.STEP_4_CUSTOMER_INFO && <CustomerInfo step={step} />}
      </Container>
    </BookingPageStyled>
  );
};

export default BookingPage;

const BookingPageStyled = styled.div`
min-height:100vh;
display: flex;
flex-direction: column;
  .loading {
    display:flex;
    align-items:center;
    justify-content: center;
    position: fixed;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    inset-inline-start: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.15);
  }

.body {
  flex:1;
}
`;
