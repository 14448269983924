import { createSlice } from '@reduxjs/toolkit';
import { IState } from '../types/reducer';
import { NAME_SERVICE, STEPS, TAB } from './constants';
import actions from './actions';
import moment from 'moment';

const initialState: IState = {
  step: STEPS.STEP_1_SELLING,
  currService: null,
  currStaff: null,
  activeTab: TAB.SERVICE,
  appointments: [],
  storeInfo: null,
  staffs: [],
  services: [],
  bookingDate: moment().format(),
};

export const Slice = createSlice({
  name: NAME_SERVICE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.resetData, (state) => {
        state.step = STEPS.STEP_1_SELLING;
        state.currService = null;
        state.currStaff = null;
        state.activeTab = TAB.SERVICE;
        state.appointments = [];
        state.bookingDate = moment().format();
      })
      .addCase(actions.setBookingDate, (state, { payload }) => {
        state.bookingDate = payload;
      })
      .addCase(actions.setStep, (state, { payload }) => {
        state.step = payload;
      })
      .addCase(actions.getServices.success, (state, { payload }) => {
        state.services = payload;
      })
      .addCase(actions.getAllStaffByShop.success, (state, { payload }) => {
        state.staffs = payload;
      })
      .addCase(actions.getStoreInfoByUrlName.success, (state, { payload }) => {
        state.storeInfo = payload;
      })
      .addCase(actions.setAppointments, (state, { payload }) => {
        state.appointments = payload;
      })
      .addCase(actions.setActiveTab, (state, { payload }) => {
        state.activeTab = payload;
      })
      .addCase(actions.setCurrService, (state, { payload }) => {
        state.currService = payload;
      })
      .addCase(actions.setCurrStaff, (state, { payload }) => {
        state.currStaff = payload;
      });
  },
});
const bookingServiceReducer = Slice.reducer;
export default bookingServiceReducer;
