import { Button, Col, Row } from 'antd';
import Container from 'components/layout/Container';
import bookingActions from 'features/Booking/services/actions';
import { TAB } from 'features/Booking/services/constants';
import bookingSelector from 'features/Booking/services/selectors';
import React from 'react';
import { useAppDispatch } from 'store/hooks';
import { styled } from 'styled-components';
import BookingDate from '../../DateBooking';
import ServiceTabContent from './TabContents/ServiceTabContent';
import StaffTabContent from './TabContents/StaffTabContent';

type ISellingLayoutProps = {};
const SellingLayout: React.FC<ISellingLayoutProps> = () => {
  const dispatch = useAppDispatch();
  const activeTab = bookingSelector.getActiveTab();
  const setActiveTab = (val: string) => {
    dispatch(bookingActions.setActiveTab(val));
  };

  return (
    <SellingLayoutStyled>
      <Container className='tab-content'>
        <Row justify='center' className='date-picker'>
          <Col flex={'auto'} className='tab'>
            <BookingDate />
          </Col>
        </Row>
        <Row justify='center'>
          <Col flex={'auto'} className='tab'>
            <Button type={activeTab !== TAB.SERVICE ? 'default' : 'primary'} onClick={() => setActiveTab(TAB.SERVICE)}>Services</Button>
          </Col>
          <Col xs={1} sm={4} />
          <Col flex={'auto'} className='tab'>
            <Button type={activeTab !== TAB.STAFF ? 'default' : 'primary'} onClick={() => setActiveTab(TAB.STAFF)}>Staffs</Button>
          </Col>
        </Row>
      </Container>
      {activeTab === TAB.SERVICE && <ServiceTabContent key={TAB.SERVICE} />}
      {activeTab === TAB.STAFF && <StaffTabContent key={TAB.STAFF} />}
    </SellingLayoutStyled>
  );
};

export default SellingLayout;
const SellingLayoutStyled = styled.div`
  .tab-row-center {
    justify-content:center;
  }
  .tab {
    button {
      width:100%;
    }
  }
  .tab-content {
    padding: 1.25rem 0;
  }
  .date-picker {
    margin-bottom: 1.25rem; 
  }
`;
