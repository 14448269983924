import Modal from 'components/Modal';
import { IStaffItem } from 'features/Booking/types/staff';
import React from 'react';
import StaffsSelection from '../Selling/TabContents/StaffTabContent/StaffsSelection';
type IModalStaffsSelectionProps = {
  visible?: boolean;
  handleClose?: () => void;
  onChooseStaff?: (val: IStaffItem | null | undefined) => void;
};
const ModalStaffsSelection: React.FC<IModalStaffsSelectionProps> = ({ visible, handleClose, onChooseStaff }) => {
  return (
    <Modal title='Select Staff' visible={visible} onClose={handleClose}>
      <StaffsSelection disableSearch setStaff={onChooseStaff} />
    </Modal>
  );
};

export default ModalStaffsSelection;
