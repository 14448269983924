import { Card, Col, Row } from 'antd';
import { IServiceItem } from 'features/Booking/types/service';
import React from 'react';
import { styled } from 'styled-components';
import { formatDurationTime } from 'utils/time';
import iconClock from 'assets/clock.png';
type IServiceItemProps = {
  data: IServiceItem;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};
const ServiceItem: React.FC<IServiceItemProps> = ({ data, onClick }) => {
  return (
    <ServiceItemStyled onClick={onClick}>
      <Row gutter={[16, 0]} className='row-content' >
        <Col flex="auto">
          <Row className='label'>{data.serviceName}</Row>
          <Row className='row-center'>
            <img src={iconClock} className='clock-icon' />
            {formatDurationTime(data.duration ?? 0)}
          </Row>
        </Col>
        <Col className='ant-col-row-center'>
          <div className="icon" />
        </Col>
      </Row>
    </ServiceItemStyled>
  );
};

export default ServiceItem;
const ServiceItemStyled = styled(Card)`
  border: 1px solid #dddde8;
  border-radius: .5rem;
  justify-content: space-between;
  padding: .9375rem;
  margin: .8rem 0 .375rem 1.875rem;

 &:hover {
    background-color:rgba(207,213,255,.3);
    cursor: pointer !important;
 }
 .row-center {
  gap:8px;
 }
  .row-content {
    display:flex;
    align-items:center;
  }
  .icon {
    border: 1px solid #dddde8;
    border-radius: 50%;
    display: inline-block;
    height: 1.5625rem;
    width: 1.5625rem;
  }
`;