import bookingSelector from 'features/Booking/services/selectors';
import React from 'react';
import { styled } from 'styled-components';
import banner from 'assets/banner-bg.jpg';
import logo from 'assets/logo.png';
type IHeadInfoProps = {};
const HeadInfo: React.FC<IHeadInfoProps> = () => {
  const storeInfo = bookingSelector.getStoreInfo();
  if (!storeInfo) return null;

  return (
    <HeadInfoStyled className='mb-spacing'>
      <div className='banner' />
      <div className="content">
        <div className="logo-container">
          <div className="wrap-logo">
            <div className='logo' />
          </div>
        </div>
        <p className='label label-size'>{storeInfo.shopName}</p>
        <p className='label-size phone-label'>{storeInfo.phone}</p>
        <p className='address-label'>{storeInfo.address}</p>
      </div>
    </HeadInfoStyled>
  );
};

export default HeadInfo;
const HeadInfoStyled = styled.div`
height: 14rem;
position: relative;
width: 100%;
display: flex;
flex-direction: column;
justify-content: end;
text-align: center;
padding-top: 0.5rem;

.banner {
  background-image: url(${banner});
  background-position: top!important;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.logo-container {
  background: hsla(0,0%,100%,.2);
  border-radius: 50%;
  left: 50%;
  position: absolute;
  top: 1.5rem;
  transform: translateX(-50%);
}

.wrap-logo {
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 1.875rem rgba(116,16,255,.1);
  height: 5.25rem;
  margin: 0.5rem;
  width: 5.25rem;
  position: relative;
}

.logo {
  background-image: url(${logo});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}

.content {
  background:#fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-top: 4rem;
}

.label-size {
  font-size: 26px;
  line-height: 1.4;
}
.phone-label {
  font-size: 20px;
  line-height: 1;
  margin-top: 0.3125rem;
}
.address-label {
  font-size: 16px;
  margin: 0.625rem auto auto;
  line-height: 1.2;
}

@media only screen and (max-width: 767px) {
  padding-top: 0;
  height: auto;
  .banner, .logo-container {
    display:none;
  }
  
}

`;
