import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Collapse, Spin } from 'antd';
import InputSearch from 'components/Atoms/InputSearch';
import Container from 'components/layout/Container';
import bookingSelector from 'features/Booking/services/selectors';
import { IServiceItem } from 'features/Booking/types/service';
import React from 'react';
import { styled } from 'styled-components';
import CategoryItem from './CategoryItem';
const expandIconStyles: React.CSSProperties = { color: '#5e73ff', fontSize: 14, fontWeight: 700 };
type IServicesSelectionProps = {
  setCurrService?: (val: IServiceItem | null | undefined) => void
};
const ServicesSelection: React.FC<IServicesSelectionProps> = ({
  setCurrService = () => undefined,
}) => {
  const data = bookingSelector.getServices();
  const loading = bookingSelector.getServicesLoading();
  if (loading) return (
    <ServicesSelectionStyled>
      <Container className='gr-center row-center'>
        <Spin spinning />
      </Container>
    </ServicesSelectionStyled>
  );

  return (
    <ServicesSelectionStyled>
      <Container>
        <InputSearch className='input-search' placeholder='Search services' />
        <Collapse
          accordion
          ghost
          items={data?.map(o => {
            const result = {
              key: o.catId,
              label: o.catName,
              children: <CategoryItem data={o} setCurrService={setCurrService} />,
            };

            return result;
          })}
          expandIcon={({ isActive }) => isActive ? <UpOutlined style={expandIconStyles} /> : <DownOutlined style={expandIconStyles} />}
          expandIconPosition='end'
        />
      </Container>
    </ServicesSelectionStyled>
  );
};

export default ServicesSelection;
const ServicesSelectionStyled = styled.div`
.gr-center {
  justify-content: center;
}
.input-search {
  margin-bottom: 1.25rem;
}
.ant-collapse {
  .ant-collapse-content>.ant-collapse-content-box {
    padding:0;
    padding-bottom: 8px;
  }
  .ant-collapse-item {
    margin-bottom: 0.75rem;
    .ant-collapse-header {
      font-size: 1.06rem;
      font-weight: 700;
      border: 1px solid #dddde8;
      border-radius: 0.5rem;
    }
    .ant-collapse-content {
   
    }
  }
}


`;
