import { CloseOutlined } from '@ant-design/icons';
import { Modal as ModalKit } from 'antd';
import React, { ReactNode } from 'react';
import { styled } from 'styled-components';
const iconStyles: React.CSSProperties = { color: '#5e73ff', fontSize: 18, fontWeight: 700 };
type IModalProps = {
  visible?: boolean;
  onClose?: () => void;
  title?: string;
  children?: ReactNode;
};
const Modal: React.FC<IModalProps> = ({ title, visible, onClose, children }) => {
  return (
    <ModalKit
      title={title ?? 'Modal'}
      open={visible}
      width={768}
      footer={null}
      centered
      onCancel={onClose}
      closeIcon={<CloseOutlined style={iconStyles} />}
    >
      <ModalStyled>
        {children}
      </ModalStyled>
    </ModalKit>
  );
};

export default Modal;
const ModalStyled = styled.div`
  min-height: 60vh;
`;
