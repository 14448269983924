import { AxiosResponse } from 'axios';
import { all, put, takeLatest } from 'redux-saga/effects';
import { ISagaFunc } from 'services/actionConfigs';
import { ICategoryItem } from '../types/service';
import { IStaffItem } from '../types/staff';
import { IStoreInfoResData } from '../types/store';
import actions from './actions';
import api from './api';
import { setLoading } from 'services/UI/sagas';
import { PATH_LOADING } from './constants';


const getStoreInfoByUrlName: ISagaFunc<string> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getConfigData, true);
  try {
    const res: AxiosResponse<{ data: IStoreInfoResData }> = yield api.getInfoByUrl(payload);
    if (res?.data?.data) {
      const storeInfo = res.data.data;

      yield put(actions.getStoreInfoByUrlName.success(storeInfo));
      yield all([
        put(actions.getAllStaffByShop.fetch(storeInfo.id)),
        put(actions.getServices.fetch(storeInfo.id)),
      ]);
    }
  } catch (error) {
    yield put(actions.getStoreInfoByUrlName.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getConfigData, false);
  }
};

const getAllStaffByShop: ISagaFunc<IStoreInfoResData['id']> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getAllStaffByShop, true);
  try {
    const res: AxiosResponse<{ data: IStaffItem[] }> = yield api.getAllStaffByShop(payload);
    if (res?.data?.data) {
      const staffs = res.data.data;
      yield put(actions.getAllStaffByShop.success(staffs));
    }
  } catch (error) {
    yield put(actions.getAllStaffByShop.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getAllStaffByShop, false);
  }
};

const getServices: ISagaFunc<IStoreInfoResData['id']> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getServices, true);
  try {
    const res: AxiosResponse<{ data: ICategoryItem[] }> = yield api.getServices(payload);
    if (res?.data?.data) {
      const services = res.data.data;
      yield put(actions.getServices.success(services));
    }
  } catch (error) {
    yield put(actions.getServices.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getServices, false);
  }
};

export const bookingServiceSagas = function* () {
  yield takeLatest(actions.getStoreInfoByUrlName.fetch, getStoreInfoByUrlName);
  yield takeLatest(actions.getAllStaffByShop.fetch, getAllStaffByShop);
  yield takeLatest(actions.getServices.fetch, getServices);
};