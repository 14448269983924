import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Form, Input, InputRef, Spin, message } from 'antd';
import AppBar from 'components/Atoms/AppBar';
import PhoneInput from 'components/PhoneInput';
import Container from 'components/layout/Container';
import bookingActions from 'features/Booking/services/actions';
import bookingApis from 'features/Booking/services/api';
import { STEPS } from 'features/Booking/services/constants';
import bookingSelector from 'features/Booking/services/selectors';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { styled } from 'styled-components';
const FORMAT_TIME = 'YYYY-MM-DD HH:mm:ss';
const formOffset = { xs: 23, md: 19, lg: 17, xl: 15, xxl: 13 };
const phone_reg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
export interface ICustomerInfoFormValues {
  note: string;
  name: string;
  phone: string;
  email: string;
}

type ICustomerInfoProps = {
  step: string;
};
const CustomerInfo: React.FC<ICustomerInfoProps> = ({ step }) => {
  const inputRef = useRef<InputRef>(null);
  const [loading, setLoading] = useState(false);
  const [isAcceptRule, setAcceptRule] = useState(false);

  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const storeInfo = bookingSelector.getStoreInfo();
  const bookingDate = bookingSelector.getBookingDate();
  const appointments = bookingSelector.getAppointments();

  useEffect(() => {
    if (step === STEPS.STEP_4_CUSTOMER_INFO) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 200);
    }
  }, [step]);

  const onBack = () => {
    dispatch(bookingActions.setStep(STEPS.STEP_3_APPOINTMENT));
  };
  const checkValid = async () => {
    if (!isAcceptRule) return false;
    try {
      await form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  };

  const _submit = async () => {
    const isValid = await checkValid();
    if (!isValid) return;
    form.submit();
  };

  const handleSubmit = async (values: ICustomerInfoFormValues) => {
    const bookingDateMoment = moment(bookingDate);
    const payload: any = {
      items: appointments.map(o => {
        const timeStart = moment(`${bookingDateMoment.format('YYYY-MM-DD')} ${o.timeSlot}`);
        const timeEnd = timeStart.clone().add(o.duration, 'minute');
        return ({
          startTime: timeStart.format(FORMAT_TIME),
          endTime: timeEnd.format(FORMAT_TIME),
          staffId: o.staff_info.id,
          lstService: [
            {
              serviceId: o.serviceId,
              type: 1
            }
          ]
        });
      }),
      note: values.note || null,
      groupTotal: null,
      customerName: values.name || null,
      customerId: null,
      email: values.email || null,
      customerPhone: values.phone?.replace(/[^0-9]/g, '') || null,
      isdublicateBook: null,
      staffLoginId: null,
      timezone: bookingDateMoment.format('GMTZZ'),
      deviceId: null,
      shopId: storeInfo?.id,
    };

    setLoading(true);
    try {
      const res = await bookingApis.bookAppointmentV3(payload);
      const _msg = res.data.data?.message;
      if (res?.data?.data.appointmentId) {
        message.success(_msg);
        dispatch(bookingActions.resetData());
      } else {
        message.warning(_msg);
      }
    } catch (error) {
      message.error('An error occurred. Please try again');
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomerInfoStyled>
      <Spin tip="Loading" size="large" spinning={loading} wrapperClassName='wrapperClassName_SPIN' rootClassName='rootClassName_SPIN' className='className_SPIN'>
        <AppBar title='' className='mb-spacing' onBack={onBack} />
        <Form
          size='large'
          form={form}
          onFinish={handleSubmit}
          name="basic"
          className='form-customer-info'
          validateTrigger={['onSubmit']}
        >
          <div className='form'>
            <Container offset={formOffset} className='customer_infos'>
              <div className="greeting">
                <h2 className='title'>How do we say hello?</h2>
                <h3>
                  Your name and phone number will be used to send you appointment confirmations and reminders. We’ll also be able to call or text you if anything changes.
                </h3>
              </div>
              <Form.Item name={'name'} rules={[{ required: true, message: 'Name is required' }]}>
                <Input placeholder='Full Name(*)' ref={inputRef} />
              </Form.Item>
              <Form.Item name={'phone'}
                rules={[{
                  validator(rule, value,) {
                    if (!value) return Promise.reject(new Error('Phone is required'));
                    if (value?.replace(/[^0-9]/g, '')?.length > 10) return Promise.reject(new Error('The phone number has 10 digits'));
                    if (!phone_reg.test(value)) {
                      return Promise.reject(new Error('The phone number invalid'));
                    }
                    return Promise.resolve();
                  },
                }]}>
                <PhoneInput />
              </Form.Item>
              <Form.Item name={'email'} rules={[{ type: 'email', message: 'Please enter a valid email' }]}>
                <Input placeholder='Email' />
              </Form.Item>
              <p className="note">
                (*) is required. Please check your input information before submit.
              </p>
              <Form.Item name={'note'}>
                <Input.TextArea rows={4} placeholder='Appointment Notes' />
              </Form.Item>
              <button type='button' className="radio term" onClick={() => setAcceptRule(!isAcceptRule)}>
                <div className="icon">
                  {isAcceptRule ? <CheckCircleFilled className='checkbox-checked' /> : <div className='checkbox' />}
                </div>
                <p>
                  I have read and agree to the
                  <a>{' '} cancellation policy {' '} </a>
                  of {storeInfo?.shopName} also agree to {storeInfo?.businessName}’s
                  <a target="_blank">{' '} terms and conditions/privacy policy.{' '} </a>
                </p>
              </button>
              <div className='form-footer'>
                <Button type='primary' disabled={!isAcceptRule} className={`round ${!isAcceptRule ? 'disabled_cus' : ''}`} htmlType='button' onClick={_submit}>Book appointment</Button>
                <div className="memo">
                  <p className="memo-text">
                    By creating this appointment, you agree to receive automated transactional and booking reminder text messages from this merchant.
                  </p>
                </div>
              </div>
            </Container>
          </div>
        </Form>
      </Spin>
    </CustomerInfoStyled >
  );
};

export default CustomerInfo;
const CustomerInfoStyled = styled.div`
  height:100%;
 .greeting {
  text-align: center;
  h2 {
    font-weight: 700;
  }
  h3 {
    font-size: 16px;
    font-weight: 400;
  }
 }
  .note {
    color: #5e73ff;
    text-align: center;
    margin-top: 0;
    padding-bottom: 10px;
    text-align: center;
    font-size: 16px;
  }
  .term, .form-footer {
    margin-bottom:16px;
  }
  .memo-text {
    font-size:16px;
    text-align:center;
  }
  .memo {
    margin-top:16px;
  }
  .ant-form-item-has-error {
    input {
      border-color: #ff4d4f;
      &:hover {
        border-color: #ffa39e;
      }
      &:focus {
        border-color: #ff4d4f;
      }
    }
  }
`;
