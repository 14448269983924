import { Input, InputProps } from 'antd';
import React from 'react';
import { styled } from 'styled-components';
import searchIcon from 'assets/search.png';

interface IInputSearchProps extends InputProps { }
const InputSearch: React.FC<IInputSearchProps> = ({ ...props }) => {
  return (
    <InputSearchStyled {...props} prefix={<img src={searchIcon} className='search-img' />} />
  );
};

export default InputSearch;
const InputSearchStyled = styled(Input)`
&.ant-input-affix-wrapper {
  background: #f6f6f6;
  border-radius: inherit;
  min-height: 2.5rem;
  padding: 0 0.9375rem!important;
  border-radius: 2.5rem;
  border: none;
  outline:none;
  border-inline-end-width:none;
  box-shadow:none;
  &:hover{ 
    background: rgba(0,0,0,.12);
  }
  
  &:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: transparent;
  }
  .ant-input {
    background: transparent;
  }
  .ant-input-prefix {
    margin-inline-end: 16px;
  }
}

.search-img {
  height: 1.09rem;
  left: 1.42rem;
  top: 0.67rem;
  width: 1.09rem;
  z-index: 2;
}
`;
