import { Button, Col, DatePicker, Empty, Row, Spin } from 'antd';
import Modal from 'components/Modal';
import dayjs from 'dayjs';
import bookingActions from 'features/Booking/services/actions';
import bookingApis from 'features/Booking/services/api';
import bookingSelector from 'features/Booking/services/selectors';
import { IServiceItem } from 'features/Booking/types/service';
import moment from 'moment';
import React, { useEffect, useState, useTransition } from 'react';
import { useAppDispatch } from 'store/hooks';
import { styled } from 'styled-components';
export type IBookingData = {
  service: IServiceItem | null | undefined;
  staff: any;
};
export type IModalTimeSlotPickerProps = {
  handleClose?: () => void;
  bookingData?: IBookingData | null | undefined;
  onChooseTimeSlot: (timeSlot: string, bookingData: IBookingData) => void;
};
const ModalTimeSlotPicker: React.FC<IModalTimeSlotPickerProps> = ({ bookingData, handleClose = () => undefined, onChooseTimeSlot = () => undefined }) => {
  const [, startTransition] = useTransition();
  const dispatch = useAppDispatch();
  const storeInfo = bookingSelector.getStoreInfo();
  const bookingDate = bookingSelector.getBookingDate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<string[]>([]);
  const reset = () => {
    startTransition(() => {
      setLoading(false);
      setData([]);
    });
  };

  const fetchData = async () => {
    if (!storeInfo || !bookingData) return;
    const staffId = bookingData?.staff?.id?.toString();
    setData([]);
    setLoading(true);
    try {
      const res = await bookingApis.getTimeSlotsByStaff({
        shopId: storeInfo.id,
        date: moment(bookingDate).format('YYYY-MM-DD'),
        staffId,
      });
      if (res?.data?.data) {
        setData(res?.data?.data);
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };

  const _onClose = () => {
    reset();
    handleClose();
  };

  useEffect(() => {
    fetchData();
  }, [bookingData, bookingDate]);

  const _onChooseTimeSlot = (timeSlot: string) => () => {
    if (!bookingData) return;
    onChooseTimeSlot(timeSlot, bookingData);
  };

  return (
    <Modal title='Select time' visible={!!bookingData} onClose={_onClose}>
      <ModalStyled className='ModalStyled'>
        <div className="sticky">
          <DatePicker
            className='date-picker'
            format={'MM-DD-YYYY'}
            inputReadOnly
            allowClear={false}
            value={dayjs(bookingDate)}
            onChange={day => {
              if (!day) return;
              dispatch(bookingActions.setBookingDate(day.format()));
            }}
          />
        </div>

        <Row gutter={[8, 8]}>
          {
            data?.length > 0 ?
              data.map((o) => (
                <Col key={o} xs={8} sm={6} md={4} >
                  <Button onClick={_onChooseTimeSlot(o)}>{o}</Button>
                </Col>))
              :
              !loading ? <div className='empty'>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div> : null
          }
        </Row>
        {!!loading && <div className='spin'> <Spin spinning={loading} /></div>}
      </ModalStyled>
    </Modal>
  );
};

export default ModalTimeSlotPicker;
const ModalStyled = styled.div`
  .empty, .spin {
    display: flex;
    align-items: center;
    justify-content: center;
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
  }
  .date-picker {
    margin-bottom: 8px;
    &.ant-picker .ant-picker-input>input {
      margin-left: 24px;
    }
  }
  .sticky {
    position: sticky;
    top: 1;
    top: 0;
    background: #fff;
    z-index: 99;
  }
`;
