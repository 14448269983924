import { Select } from 'antd';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';

const PhoneInput = (props: any) => {
  return (
    <PhoneInputStyled>
      <MaskedInput
        {...props}
        className={'phone-input ' + props.className}
        mask={
          ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
        }
        placeholder='(000) 000-0000'
      />
    </PhoneInputStyled>
  );
};

export default PhoneInput;

const PhoneInputStyled = styled.div`
display: flex;
flex:1;
input {
  padding: 12px 11px;
  border-width: 2px;
  background: #f6f6f6;
  border: 2px solid #f6f6f6;
  font-size: 16px;
  line-height: 1.5;
  width: 100%;
  border-radius: 8px;
  &:focus, &:hover {
    outline: none;
    border-color: #4096ff;
    border-inline-end-width: 1px;
  }
  &::placeholder {
    color: #B8B8B8;
  }
  
}
`;