import { CloseOutlined } from '@ant-design/icons';
import { Card, Col, Row } from 'antd';
import iconClock from 'assets/clock.png';
import { IServiceSelectedItem } from 'features/Booking/types/service';
import useDetachScreen from 'hooks/useDetachScreen';
import React from 'react';
import { styled } from 'styled-components';
import { formatDurationTime } from 'utils/time';

// const iconStyles: React.CSSProperties = { color: '#5e73ff', fontSize: 18, fontWeight: 700 };

type IServiceSelectedItemProps = {
  data: IServiceSelectedItem;
  onRemove?: () => void;
};
const ServiceSelectedItem: React.FC<IServiceSelectedItemProps> = ({ data, onRemove }) => {
  const { isMobile } = useDetachScreen();
  return (
    <ServiceSelectedItemStyled>
      <Row gutter={[16, 0]} className='row-center row-content'>
        <Col flex="auto">
          <Row className="service_name_content">
            <p className='service-name'>{data.serviceName} {' '}</p>
            <Row className="row-center duration">
              <img src={iconClock} className='clock-icon' />
              <span>{formatDurationTime(data.duration ?? 0, isMobile)}</span>
            </Row>
          </Row>
          <p className='staff-name'>{data.staff_info?.fullName}</p>
        </Col>
        <Col className='right-content'>
          <div className="icon-close" onClick={onRemove}>
            <CloseOutlined className='icon-close' />
          </div>
          <div>
            <div className='button-time-slot'>{data.timeSlot}</div>
          </div>
        </Col>
      </Row>
    </ServiceSelectedItemStyled>
  );
};

export default ServiceSelectedItem;
const ServiceSelectedItemStyled = styled(Card)`
position:relative;
background-color: #fff;
border-color: #fff;
color: rgba(0,0,0,.87);
border: 0.5px solid #f1f0f5!important;
border-radius: 0.75rem;
box-shadow: 10px 24px 54px rgba(0,0,0,.06)!important;
margin: 0.75rem 0;
padding: .9375rem 1.25rem .9375rem 0.9375rem;
.row-content {
  flex-wrap: nowrap;
}
p {
  font-size:18px;
}
.staff-name {
  color: #5e73ff;
  font-weight:600;
}
.icon-close {
  cursor: pointer;
}
.service-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.service_name_content {
  gap: 14px;
  flex-wrap: nowrap;
}
.duration{
  gap: 6px;
  flex-wrap: nowrap;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
}
.icon-close-wrap {
  position:absolute;
  right:10px;
  top:10px;
}
.icon-close {
  color: #5e73ff; 
  font-size: 18px; 
  font-weight: 700;
}
.right-content {
  display: flex;
  flex-direction: column;
  align-items:flex-end;
  align-self: stretch;
  justify-content: space-between;
}
.button-time-slot {
  box-shadow: none;
  border-radius: 1.34rem;
  font-size: 14px;
  padding:2px;
  text-align:center;
  text-transform: capitalize;
  width: 100%;
  font-weight: 600;
  border: 1px solid #5e73ff;
  color: #5e73ff;
  cursor: pointer;
}

@media only screen and (max-width: 575.95px) {
  .service-name, .staff-name, .icon-close {
    font-size: 16px;
  }
  .button-time-slot, .duration {
    font-size: 14px;
  }
}
@media only screen and (max-width: 349.95px) {
  .service-name, .staff-name, .icon-close {
    font-size: 14px;
  }
  .button-time-slot, .duration {
    font-size: 12px;
  }
}

@media only screen and (max-width: 310.95px) {
  .service_name_content {
    flex-wrap: wrap;
  }
  .button-time-slot, .duration {
    font-size: 10px;
  }
}
`;
