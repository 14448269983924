import { ICategoryItem, IServiceItem } from 'features/Booking/types/service';
import React from 'react';
import { styled } from 'styled-components';
import ServiceItem from './ServiceItem';
type ICategoryItemProps = {
  data: ICategoryItem;
  setCurrService?: (val: IServiceItem | null | undefined) => void
};
const CategoryItem: React.FC<ICategoryItemProps> = ({ data, setCurrService = () => undefined }) => {
  return (
    <CategoryItemStyled>
      {data.services?.map(o => (
        <ServiceItem key={o.serviceId} data={o} onClick={() => setCurrService(o)} />
      ))}
    </CategoryItemStyled>
  );
};

export default CategoryItem;
export const CategoryItemStyled = styled.div``;
