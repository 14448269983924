import { Col, Row, Spin } from 'antd';
import InputSearch from 'components/Atoms/InputSearch';
import Container from 'components/layout/Container';
import bookingSelector from 'features/Booking/services/selectors';
import { IStaffItem } from 'features/Booking/types/staff';
import React from 'react';
import { styled } from 'styled-components';
import StaffItem from './StaffItem';

type IStaffsSelectionProps = {
  disableSearch?: boolean;
  setStaff?: (val: IStaffItem | null | undefined) => void
};
const StaffsSelection: React.FC<IStaffsSelectionProps> = ({ disableSearch, setStaff = () => undefined }) => {
  const data = bookingSelector.getStaffs();
  const loading = bookingSelector.getAllStaffByShopLoading();
  if (loading) return (
    <StaffsSelectionStyled>
      <Container className='gr-center row-center'>
        <Spin spinning />
      </Container>
    </StaffsSelectionStyled>
  );
  return (
    <StaffsSelectionStyled>
      {!disableSearch && <Container className='input-search'>
        <InputSearch placeholder='Search staff' />
      </Container>
      }

      <Row className='row-staff'>
        {data.map(o => (
          <Col key={o.id} xs={24} md={12}><StaffItem data={o} onClick={() => setStaff(o)} /></Col>
        ))}
      </Row>
    </StaffsSelectionStyled>
  );
};

export default StaffsSelection;
const StaffsSelectionStyled = styled.div`
.gr-center {
  justify-content: center;
}
.input-search {
  margin-bottom: 1.25rem;
}
.row-staff {
  margin:0 -0.72rem;
}
`;
