import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import ServicesSelection from '../Selling/TabContents/ServiceTabContent/ServicesSelection';
import ModalStaffsSelection from './ModalStaffsSelection';
import AppBar from 'components/Atoms/AppBar';
import bookingSelector from 'features/Booking/services/selectors';
import { useAppDispatch } from 'store/hooks';
import bookingActions from 'features/Booking/services/actions';
import { STEPS } from 'features/Booking/services/constants';
import { IServiceItem } from 'features/Booking/types/service';
import { IStaffItem } from 'features/Booking/types/staff';
import ModalTimeSlotPicker, { IBookingData, IModalTimeSlotPickerProps } from './ModalTimeSlotPicker';
import { last } from 'lodash';
import moment from 'moment';
type IServiceLayoutProps = {};
const ServiceLayout: React.FC<IServiceLayoutProps> = () => {
  const dispatch = useAppDispatch();
  const [visibleModal, setVisibleModal] = useState(false);
  const currService = bookingSelector.getCurrentService();
  const currStaff = bookingSelector.getCurrentStaff();
  const appointments = bookingSelector.getAppointments();
  const [bookingData, setOpenTimeSlotData] = useState<IBookingData | null | undefined>(null);
  useEffect(() => {
    if (!currService) return;
    setVisibleModal(true);
  }, []);

  const openTimeSlot = (service: IServiceItem | null | undefined, staff: any) => {
    const servicesOfStaff = appointments.filter(o => o?.staff_info.id === staff?.id);
    const isExistStaff = servicesOfStaff.length > 0;
    if (isExistStaff) {
      const lastService = last(servicesOfStaff);
      if (lastService) {
        const bookingDateMoment = moment();
        const timeStart = moment(`${bookingDateMoment.format('YYYY-MM-DD')} ${lastService?.timeSlot}`, 'YYYY-MM-DD LT').add(lastService?.duration ?? 0, 'minute');
        const timeSlot = timeStart.format('LT');
        onChooseTimeSlot(timeSlot, { service, staff });
      }
    } else {
      setOpenTimeSlotData({ service, staff });
    }
  };


  const onBack = () => {
    dispatch(bookingActions.setStep(STEPS.STEP_1_SELLING));
  };

  const setCurrService = (val: IServiceItem | null | undefined) => {
    if (currStaff) {
      dispatch(bookingActions.setCurrService(val));

      openTimeSlot(val, currStaff);
      return;
    }
    dispatch(bookingActions.setCurrService(val));
    setVisibleModal(true);
  };

  const onChooseStaff = (val: IStaffItem | null | undefined) => {
    dispatch(bookingActions.setCurrStaff(val));

    openTimeSlot(currService, val);
  };

  const handleClose = () => {
    setVisibleModal(false);
    dispatch(bookingActions.setCurrService(null));
    dispatch(bookingActions.setCurrStaff(null));
  };

  const onChooseTimeSlot: IModalTimeSlotPickerProps['onChooseTimeSlot'] = (timeSlot, _bookingData) => {
    const { service, staff } = _bookingData;
    const isExist = appointments.find(o => o?.serviceId === service?.serviceId && o?.staff_info.id === staff?.id && o.timeSlot === timeSlot);
    if (!isExist) {
      const _appointments = [...appointments];
      // @ts-ignore
      _appointments.push({ ...service, staff_info: staff, timeSlot });
      dispatch(bookingActions.setAppointments(_appointments));
      dispatch(bookingActions.setStep(STEPS.STEP_3_APPOINTMENT));
    }
  };

  return (
    <ServiceLayoutStyled>
      <AppBar title='Select service' className='mb-spacing' onBack={onBack} />
      <ServicesSelection setCurrService={setCurrService} />
      <ModalStaffsSelection visible={visibleModal} handleClose={handleClose} onChooseStaff={onChooseStaff} />
      <ModalTimeSlotPicker
        bookingData={bookingData}
        handleClose={() => setOpenTimeSlotData(null)}
        onChooseTimeSlot={onChooseTimeSlot}
      />
    </ServiceLayoutStyled>
  );
};

export default ServiceLayout;
const ServiceLayoutStyled = styled.div``;
