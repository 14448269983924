import primaryChevronLeft from 'assets/primary-chevron-left.png';
import Container from 'components/layout/Container';
import React from 'react';
import { styled } from 'styled-components';

type IAppBarProps = {
  title?: string;
  className?: string;
  onBack?: () => void;
};
const AppBar: React.FC<IAppBarProps> = ({ title, className, onBack }) => {
  return (
    <Container offset={{ xs: 23, md: 24 }} className={className}>
      <AppBarStyled>
        <div className='icon' onClick={onBack}>
          <img src={primaryChevronLeft} />
        </div>
        <p className='label'>{title}</p>
      </AppBarStyled>
    </Container>
  );
};

export default AppBar;
const AppBarStyled = styled.div`
position:relative;
display:flex;
align-items:center;
justify-content:center;
background: #f6f6f6;
min-height: 2.12rem;
.icon {
  position: absolute;
  left: 10px;
  height: 100%;
  width: 40px;
  z-index: 2;
  img {
    width:100%;
    height:100%;
  }
  &:hover {
    cursor:pointer;
  }
}
`;
